import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField, Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import { apiUrl } from "../../config";
import jwt_decode from "jwt-decode";
import AdminNavbar from "../../components/AdminNavbar/AdminNavbar";
import {
  getAllRegister,
  allRegisterClear,
  getAllRegisterLength,
  searchRegister,
} from "../../redux/actions/registerAction";

import view from "../../resources/img/view.png";
import RadioComponent from "../../components/Radio";
import { CSVLink, CSVDownload } from "react-csv";
import OnLineErrors from "../../components/OnLineErrors";
import Select from "react-select";
import { saveAs } from "file-saver";
import axios from "axios";
//import './pagestyle.css'
import download from "../../resources/img/download.png";

import { useDispatch, useSelector } from "react-redux";
var datetime = new Date();
var tdy_date = datetime
  .toISOString()
  .slice(0, 10)
  .split("-")
  .reverse()
  .join("-");
var t_date = datetime.toISOString().slice(0, 10).split("-").join("-");

export default function ViewRegisteration() {
  const [id, setid] = useState("");

  const [errors, seterrors] = useState([]);

  const [search, setsearch] = useState("");
  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");
  const [trigger, settrigger] = useState("#myModal");

  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: errors.units === "*" ? "red" : "gray",
    }),
  };

  const register_state = useSelector((state) => state.getAllRegisterReducer);
  const { register } = register_state;
  console.log("!!!!!!!", register);

  // const userState = useSelector(state=>state.loginUserReducer)
  // const {currentUser} = userState;

  useEffect(() => {
    // if(currentUser.length>0){
    //   var decoded = jwt_decode(userState.currentUser);
    //    setuser(decoded.username)
    //     if(decoded.role !== 'admin')
    //      {
    //       window.location.href="/"
    //      }
    //     }
    //    else { window.location.href="/" }

    dispatch(allRegisterClear());
    dispatch(getAllRegisterLength()).then((response) => {
      setTot(response.response.data.results[0].tot);
    });
    dispatch(getAllRegister(1, itemsPerPage));
  }, []);

  //console.log('!!!!--USER-!!!',user)

  useEffect(() => {
    setData(register);
  }, [register]);

  // useEffect(() => {
  //   dispatch(allOrdersClear())
  //   dispatch(getAllOrdersLength()).then((response)=>{
  //    setTot(response.response.data.results[0].tot)
  //   })
  //   dispatch(getAllOrders(1,itemsPerPage))
  //   dispatch(getAllCompanyNames())
  //   handleClientList()
  // },[])

  const dispatch = useDispatch();

  const handleClear = () => {};

  // ------------------------- EDIT START --------------------------- //

  // ------------------------- Pagination start ------------------------------ //
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const [tot, setTot] = useState(0);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [data, setData] = useState([]);
  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
    dispatch(getAllRegister(Number(event.target.id), itemsPerPage));
  };
  const pages = [];
  for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
    pages.push(i);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    dispatch(getAllRegister(currentPage, itemsPerPage));
  }, [currentPage, itemsPerPage]);

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
  }
  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
  }
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleLoadMore = () => {
    setcurrentPage(1);
    setmaxPageNumberLimit(5);
    setminPageNumberLimit(0);
    setitemsPerPage(itemsPerPage + 5);
  };

  // ------------------------ Pagination end ------------------------ //

  return (
    <>
      <AdminNavbar />
      <Section>
        <div
          className="table-responsive"
          style={{ marginBottom: "2rem", padding: "3rem" }}
        >
          <table className="table table-bordered table-responsive-sm">
            <thead style={{ backgroundColor: "#00b894", color: "#fff" }}>
              <tr>
                <th>EntryDate</th>
                <th>Name</th>
                <th>Designation</th>
                <th>Affiliation</th>
                <th>Address</th>
                <th>E-Mail</th>
                <th>Contact Number</th>
                <th>Paper Title</th>
                <th>Abstract</th>
                <th>Keywords</th>
                <th>Corresponding Author Details</th>
                <th>Co Author Details</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((reg, index) => {
                  return (
                    // <tr key={index} onClick={() => {handleModal(reg)}} data-toggle="modal" data-target={trigger}>
                    <tr
                      key={index}
                      style={{
                        backgroundColor:
                          reg.status === "deleted" ? "#ff7979" : "",
                      }}
                    >
                      {/* <td> {((props.cp-1)*5)+(index+1)} </td> */}
                      {/* <td> {(index+1)} </td>                         */}
                      <td> {moment(reg.entry_date).format("DD-MM-YY")} </td>
                      <td>
                        {reg.first_name} {reg.last_name}
                      </td>
                      <td>{reg.designation}</td>
                      <td>{reg.affiliation} </td>
                      <td>
                        {reg.address}, {reg.city}, {reg.state}, {reg.country},
                        {reg.zip_postal_code}
                      </td>
                      <td>{reg.email}</td>
                      <td>{reg.contact_number}</td>
                      <td>{reg.paper_title}</td>
                      <td>{reg.abstract}</td>
                      <td>{reg.keywords}</td>
                      <td>{reg.corresponding_author_details}</td>
                      <td>{reg.co_author_details}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <>
          <div className="pageNumbers">
            <button
              onClick={handlePrevbtn}
              disabled={currentPage == pages[0] ? true : false}
            >
              {" "}
              &lt;&lt;
            </button>

            {pageDecrementBtn}
            {renderPageNumbers}
            {pageIncrementBtn}
            <button
              onClick={handleNextbtn}
              disabled={currentPage == pages[pages.length - 1] ? true : false}
            >
              {" "}
              &gt;&gt;
            </button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 50px",
            }}
          >
            <button onClick={handleLoadMore} className="loadmore">
              Load More
            </button>
          </div>
        </>
      </Section>
    </>
  );
}

const Section = styled.section`
  .view__acc_container {
    margin: 100px 50px;
  }

  .view__filterbox__container {
    margin: 20px auto;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background-color: #b8e994;
  }
  .view__filterdate__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex: 0.7;
  }
  .view__filtersearch__container {
    flex: 0.3;
    text-align: center;
  }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    .view__filterbox__container {
      display: flex;
      flex-direction: column;
    }
    .view__filterdate__container {
      display: flex;
      flex-direction: column;
    }
  }
`;
