import axios from "axios";
import { apiUrl } from "../../config";

export const addEmployeemaster = (reg) => async (dispatch) => {
  console.log("ACTION------>", reg);
  dispatch({ type: "ADD_EMPLOYEEMASTER_REQUEST" });
  try {
    const response = await axios.post(
      `${apiUrl}/api/employeemaster/create`,
      reg
    );
    console.log("+++ add", response);
    dispatch({ type: "ADD_EMPLOYEEMASTER_SUCCESS" });
    return {
      response,
    };
  } catch (error) {
    dispatch({ type: "ADD_EMPLOYEEMASTER_FAILED", payload: error });
  }
};

export const allEmployeeMasterClear = (acc) => async (dispatch) => {
  try {
    dispatch({ type: "ALL_EMPLOYEEMASTER_CLEAR" });
  } catch (error) {
    dispatch({ type: "ALL_EMPLOYEEMASTER_FAILED", payload: error });
  }
};

export const getAllEmployeeMasterLength = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/employeemaster/getall_employee_master_length`
    );
    console.log("%%%111%%%", response.data.results[0].tot);
    return { response };
  } catch (error) {}
};

export const getAllEmployeeMaster = (offset, row_count) => async (dispatch) => {
  dispatch({ type: "GET_EMPLOYEEMASTER_REQUEST" });

  try {
    const response = await axios.get(
      `${apiUrl}/api/employeemaster/getall_employee_master/${offset}/${row_count}`
    );

    //console.log("%%%%%%%%%%%%%%%%%%%%", response.data.results);

    dispatch({
      type: "GET_EMPLOYEEMASTER_SUCCESS",
      payload: response.data.results,
    });
  } catch (error) {
    dispatch({ type: "GET_EMPLOYEEMASTER_FAILED", payload: error });
  }
};

export const updateEmployeeMaster = (acc) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/employeemaster/update`,
      acc
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const deleteEmployeemaster = (id) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}/api/employeemaster/delete`, {
      id,
    });
    return { response };
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

// export const searchRegister = (val)=>async dispatch=>{
//     const data={id:val}
//     try {
//         const response = await axios.post(`${apiUrl}/api/register/search`,data)
//         return{  response  }
//        // window.location.reload()
//     } catch (error) {
//         return{  error  }
//         //alert('Something Went Wrong')
//         console.log(error)
//     }
// }
