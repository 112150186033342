

export const getAddRegisterReducer=(state={}, action)=>{

    switch(action.type)
    {
        case 'ADD_REGISTER_REQUEST': return{
            loading:true,
            ...state
        }
        case 'ADD_REGISTER_SUCCESS': return{
            loading:false,
            success:true
        }
        case 'ADD_REGISTER_FAILED': return{
            loading:false,
            error:action.payload
        }
        
        case 'ALL_REGISTER_CLEAR': return{
            loading:false,
            success:false
        }
        
        default : return state
    }
}

export const getAllRegisterReducer=(state={register:[]}, action)=>{

    switch(action.type)
    {
        case 'GET_REGISTER_REQUEST': return{
            loading:true,
            ...state
        }
        case 'GET_REGISTER_SUCCESS': return{
            loading:false,
            register:action.payload
        }
        case 'GET_REGISTER_FAILED': return{
            loading:false,
            error:action.payload
        }
        default : return state
    }
}
