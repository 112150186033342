

export const getAddReviewerEditorReducer=(state={}, action)=>{

    switch(action.type)
    {
        case 'ADD_REVIEWER_EDITOR_REQUEST': return{
            loading:true,
            ...state
        }
        case 'ADD_REVIEWER_EDITOR_SUCCESS': return{
            loading:false,
            success:true
        }
        case 'ADD_REVIEWER_EDITOR_FAILED': return{
            loading:false,
            error:action.payload
        }
        
        case 'ALL_REVIEWER_EDITOR_CLEAR': return{
            loading:false,
            success:false
        }
        
        default : return state
    }
}

export const getAllReviewerEditorReducer=(state={reviewer_editor:[]}, action)=>{

    switch(action.type)
    {
        case 'GET_REVIEWER_EDITOR_REQUEST': return{
            loading:true,
            ...state
        }
        case 'GET_REVIEWER_EDITOR_SUCCESS': return{
            loading:false,
            reviewer_editor:action.payload
        }
        case 'GET_REVIEWER_EDITOR_FAILED': return{
            loading:false,
            error:action.payload
        }
        default : return state
    }
}
