import axios from 'axios';
import { apiUrl } from "../../config";


export const addReviewerEditor = (rev_data)=>async dispatch=>{
console.log('ACTION------>',rev_data)
//const rev_data = { reviewer_editor_name, password, email, contact_number, postal_address, country, qualification, affiliation, university_company, research_interest, lqc_cv, scopus_id, orcid_id, google_scholar_id, wos_researcher_id }
       
        const formData = new FormData();                   
        formData.append("reviewer_editor_name", rev_data.reviewer_editor_name);
        formData.append("password", rev_data.password);     
        formData.append("email", rev_data.email);  
        formData.append("contact_number", rev_data.contact_number);  
        formData.append("postal_address", rev_data.postal_address);  
        formData.append("country", rev_data.country);  
        formData.append("qualification", rev_data.qualification);  
        formData.append("affiliation", rev_data.affiliation);  
        formData.append("university_company", rev_data.university_company);  
        formData.append("research_interest", rev_data.research_interest);  
        formData.append("lqc_cv", rev_data.lqc_cv);      
        formData.append("scopus_id", rev_data.scopus_id);  
        formData.append("orcid_id", rev_data.orcid_id);  
        formData.append("google_scholar_id", rev_data.google_scholar_id);  
        formData.append("wos_researcher_id", rev_data.wos_researcher_id);    
               

        const config = {
        headers: {
            "content-type": "multipart/form-data",
            //'auth':`${JSON.parse(localStorage.getItem('auth'))}`
            // 'auth':`${JSON.parse(token)}`
        }
        };

       dispatch({type:'ADD_REVIEWER_EDITOR_REQUEST'})
       try {
           const response = await axios.post(`${apiUrl}/api/reviewer-editor/create`, formData, config)
           console.log('+++ add',response)
           dispatch({type:'ADD_REVIEWER_EDITOR_SUCCESS'})
           return{
               response
           }
          
       } catch (error) {
      
           dispatch({type:'ADD_REVIEWER_EDITOR_FAILED', payload:error}) 
       }
   }



export const allReviewerEditorClear = (acc)=>async dispatch=>{
    try {
      
        dispatch({type:'ALL_REVIEWER_EDITOR_CLEAR'})
    } catch (error) {
        dispatch({type:'ALL_REVIEWER_EDITOR_FAILED', payload:error}) 
    }
}   


export const getAllReviewerEditorLength=()=>async dispatch=>{
  
    try{
        const response =await axios.get(`${apiUrl}/api/reviewer-editor/getallreviewer_editor_length`)    
        console.log('%%%111%%%',response.data.results[0].tot);
        return{  response  }  
    } catch(error){   
    }
    }



export const getAllReviewerEditor=(offset,row_count)=>async dispatch=>{
    dispatch({type:'GET_REVIEWER_EDITOR_REQUEST'})

try{
    const response =await axios.get(`${apiUrl}/api/reviewer-editor/getallreviewer_editor/${offset}/${row_count}`)    
 
    dispatch({type:'GET_REVIEWER_EDITOR_SUCCESS',payload:response.data.results})
} catch(error){
    dispatch({type:'GET_REVIEWER_EDITOR_FAILED', payload:error})
}
}


export const deleteReviewerEditor = (accid)=>async dispatch=>{
    try {
        const response = await axios.post(`${apiUrl}/api/reviewer_editor/delete`,{accid})    
        return{  response  }
      
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}



export const searchReviewerEditor = (val)=>async dispatch=>{
    const data={id:val}
    try {
        const response = await axios.post(`${apiUrl}/api/reviewer_editor/search`,data)          
        return{  response  }
       // window.location.reload()
    } catch (error) {
        return{  error  }
        //alert('Something Went Wrong')
        console.log(error)
    }
}


