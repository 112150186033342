import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField, Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import { apiUrl } from "../../config";
import jwt_decode from "jwt-decode";
import AdminNavbar from "../../components/AdminNavbar/AdminNavbar";
import {
  getAllReviewerEditor,
  allReviewerEditorClear,
  getAllReviewerEditorLength,
} from "../../redux/actions/reviewerEditorAction";

import view from "../../resources/img/view.png";
import RadioComponent from "../../components/Radio";
import { CSVLink, CSVDownload } from "react-csv";
import OnLineErrors from "../../components/OnLineErrors";
import Select from "react-select";
import { saveAs } from "file-saver";
import axios from "axios";
//import './pagestyle.css'
import download from "../../resources/img/download.png";

import { useDispatch, useSelector } from "react-redux";
var datetime = new Date();
var tdy_date = datetime
  .toISOString()
  .slice(0, 10)
  .split("-")
  .reverse()
  .join("-");
var t_date = datetime.toISOString().slice(0, 10).split("-").join("-");

export default function ViewReviewerEditor() {
  const [id, setid] = useState("");

  const [errors, seterrors] = useState([]);

  const [search, setsearch] = useState("");
  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");
  const [trigger, settrigger] = useState("#myModal");

  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: errors.units === "*" ? "red" : "gray",
    }),
  };

  const reviewer_editor_state = useSelector(
    (state) => state.getAllReviewerEditorReducer
  );
  const { reviewer_editor } = reviewer_editor_state;
  console.log("!!!!!!!", reviewer_editor);

  // const userState = useSelector(state=>state.loginUserReducer)
  // const {currentUser} = userState;

  useEffect(() => {
    // if(currentUser.length>0){
    //   var decoded = jwt_decode(userState.currentUser);
    //    setuser(decoded.username)
    //     if(decoded.role !== 'admin')
    //      {
    //       window.location.href="/"
    //      }
    //     }
    //    else { window.location.href="/" }

    dispatch(allReviewerEditorClear());
    dispatch(getAllReviewerEditorLength()).then((response) => {
      setTot(response.response.data.results[0].tot);
    });
    dispatch(getAllReviewerEditor(1, itemsPerPage));
  }, []);

  //console.log('!!!!--USER-!!!',user)

  useEffect(() => {
    setData(reviewer_editor);
  }, [reviewer_editor]);

  // useEffect(() => {
  //   dispatch(allOrdersClear())
  //   dispatch(getAllOrdersLength()).then((response)=>{
  //    setTot(response.response.data.results[0].tot)
  //   })
  //   dispatch(getAllOrders(1,itemsPerPage))
  //   dispatch(getAllCompanyNames())
  //   handleClientList()
  // },[])

  const dispatch = useDispatch();

  const handleClear = () => {};
  const handleDownload = (fname, fname_og) => {
    console.log("********** *******", fname, fname_og);

    const downloadAs = (url, name) => {
      axios
        .get(url, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          responseType: "blob",
        })
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = name;
          a.click();
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
    downloadAs(`${apiUrl}/uploads/${fname}`, fname_og);
  };

  // ------------------------- EDIT START --------------------------- //

  // ------------------------- Pagination start ------------------------------ //
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const [tot, setTot] = useState(0);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [data, setData] = useState([]);
  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
    dispatch(getAllReviewerEditor(Number(event.target.id), itemsPerPage));
  };
  const pages = [];
  for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
    pages.push(i);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    dispatch(getAllReviewerEditor(currentPage, itemsPerPage));
  }, [currentPage, itemsPerPage]);

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
  }
  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
  }
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleLoadMore = () => {
    setcurrentPage(1);
    setmaxPageNumberLimit(5);
    setminPageNumberLimit(0);
    setitemsPerPage(itemsPerPage + 5);
  };

  // ------------------------ Pagination end ------------------------ //

  return (
    <>
      <AdminNavbar />
      <Section>
        <div
          className="table-responsive"
          style={{ marginBottom: "2rem", padding: "3rem" }}
        >
          <table className="table table-bordered table-responsive-sm">
            <thead style={{ backgroundColor: "#00b894", color: "#fff" }}>
              <tr>
                <th>EntryDate</th>
                <th>Reviewer/Editor Name</th>
                <th>Password</th>
                <th>E-Mail</th>
                <th>Contact Number</th>
                <th>PostalAddress</th>
                <th>Country</th>
                <th>Qualification</th>
                <th>Affiliation</th>
                <th>University/ Company</th>
                <th>Research Interest</th>
                <th>Latest Qualification Certificate/CV</th>
                <th>ScopusID</th>
                <th>OrcidID</th>
                <th>Google Scholar ID</th>
                <th>WoS Researcher ID</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((rev, index) => {
                  return (
                    // <tr key={index} onClick={() => {handleModal(reg)}} data-toggle="modal" data-target={trigger}>
                    <tr
                      key={index}
                      style={{
                        backgroundColor:
                          rev.status === "deleted" ? "#ff7979" : "",
                      }}
                    >
                      {/* <td> {((props.cp-1)*5)+(index+1)} </td> */}
                      {/* <td> {(index+1)} </td>                         */}
                      <td> {moment(rev.entry_date).format("DD-MM-YY")} </td>
                      <td>{rev.reviewer_editor_name}</td>
                      <td>{rev.password}</td>
                      <td>{rev.email} </td>
                      <td>{rev.contact_number}</td>
                      <td>{rev.postal_address}</td>
                      <td>{rev.country}</td>
                      <td>{rev.qualification}</td>
                      <td>{rev.affiliation}</td>
                      <td>{rev.university_company}</td>
                      <td>{rev.research_interest}</td>

                      <td
                        onClick={() =>
                          handleDownload(rev.lqc_cv, rev.lqc_cv_og)
                        }
                      >
                        {" "}
                        <a href="#">{rev.lqc_cv_og}</a>{" "}
                      </td>

                      <td>{rev.scopus_id}</td>
                      <td>{rev.orcid_id}</td>
                      <td>{rev.google_scholar_id}</td>
                      <td>{rev.wos_researcher_id}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <>
          <div className="pageNumbers">
            <button
              onClick={handlePrevbtn}
              disabled={currentPage == pages[0] ? true : false}
            >
              {" "}
              &lt;&lt;
            </button>

            {pageDecrementBtn}
            {renderPageNumbers}
            {pageIncrementBtn}
            <button
              onClick={handleNextbtn}
              disabled={currentPage == pages[pages.length - 1] ? true : false}
            >
              {" "}
              &gt;&gt;
            </button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 50px",
            }}
          >
            <button onClick={handleLoadMore} className="loadmore">
              Load More
            </button>
          </div>
        </>
      </Section>
    </>
  );
}

const Section = styled.section`
  .view__acc_container {
    margin: 100px 50px;
  }

  .view__filterbox__container {
    margin: 20px auto;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background-color: #b8e994;
  }
  .view__filterdate__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex: 0.7;
  }
  .view__filtersearch__container {
    flex: 0.3;
    text-align: center;
  }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    .view__filterbox__container {
      display: flex;
      flex-direction: column;
    }
    .view__filterdate__container {
      display: flex;
      flex-direction: column;
    }
  }
`;
