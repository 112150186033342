import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Button, Checkbox } from "@material-ui/core";
import { loginUser } from "../../../redux/actions/userActions";
import {
  addEmployeemaster,
  allEmployeeMasterClear,
  deleteEmployeemaster,
  getAllEmployeeMaster,
  updateEmployeeMaster,
} from "../../../redux/actions/employeemasterAction";
import Loading from "../../../components/Loading";
import Error from "../../../components/Error";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";
import Sidebar from "../../../components/Sidebar";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import SaveIcon from "@material-ui/icons/Save";
import OnLineErrors from "../../../components/OnLineErrors";
import ViewemployeeMaster from "./ViewEmployeeMaster";
export default function EmployeeMaster() {
  const classes = useStyles();

  const [is_edit, setis_edit] = useState(false);
  const [id, set_id] = useState("");
  const [location, set_location] = useState("");
  const [name, set_name] = useState("");
  const [aadhar_number, set_aadhar_number] = useState("");
  const [contact_number, set_contact_number] = useState("");

  const [address, set_address] = useState("");
  const [dob, set_dob] = useState("");
  const [qualification, set_qualification] = useState("");
  const [department, set_department] = useState("");
  const [salary, set_salary] = useState("");
  const [master, set_master] = useState("");
  const [role, set_role] = useState("");
  const [responsibility, set_responsibility] = useState("");

  const [joining_date, set_joining_date] = useState("");
  const [reliving_date, set_reliving_date] = useState("");

  const [errors, seterrors] = useState([]);
  const [errors_length, seterrors_length] = useState([]);

  const loginstate = useSelector((state) => state.loginUserReducer);
  const { error, loading } = loginstate;

  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const handleClear = () => {
    set_location("");
    set_name("");
    set_aadhar_number("");
    set_contact_number("");
    set_address("");
    set_dob("");
    set_qualification("");
    set_department("");
    set_salary("");
    set_master("");
    set_role("");
    set_responsibility("");
    set_joining_date("");
    set_reliving_date("");
  };

  console.log("------STATE----------", reliving_date);

  const handleEdit = (emval) => {
    console.log("%%%%%%%%%%%%%", emval.id);
    setis_edit(true);
    set_id(emval.id);
    set_location(emval.location);
    set_name(emval.name);
    set_aadhar_number(emval.aadhar_number);
    set_contact_number(emval.contact_number);
    set_address(emval.address);
    set_dob(moment(emval.dob).format("YYYY-MM-DD"));
    set_qualification(emval.qualification);
    set_department(emval.department);
    set_salary(emval.salary);
    set_master(emval.master);
    set_role(emval.role);
    set_responsibility(emval.responsibility);
    set_joining_date(moment(emval.joining_date).format("YYYY-MM-DD"));
    set_reliving_date(moment(emval.reliving_date).format("YYYY-MM-DD"));
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  };

  const handleDelete = (acc) => {
    console.log("####", acc.id);
    var option = window.confirm(`Are You Sure want to delete ${acc.name}`);
    if (option) {
      dispatch(deleteEmployeemaster(acc.id)).then((res) => {
        dispatch(allEmployeeMasterClear());
        dispatch(getAllEmployeeMaster(1, 5));
        handleClear();
      });
    }
  };

  const Submit = () => {
    const emp_data = {
      id,
      location,
      name,
      aadhar_number,
      contact_number,
      address,
      dob,
      qualification,
      department,
      salary,
      master,
      role,
      responsibility,
      joining_date,
      reliving_date,
    };
    console.log("##### Emp DATA  ######", emp_data);
    const errors = validate(emp_data);
    console.log("##### ERRORS  ######", errors);
    seterrors(errors);
    console.log("------LENGTH CHECK----------", Object.keys(errors).length);
    if (Object.keys(errors).length === 0) {
      if (!is_edit) {
        console.log("------check----------");
        dispatch(addEmployeemaster(emp_data)).then((response) => {
          console.log("@#@#@#@#", response.response.data.msg);
          alert(response.response.data.msg);
          handleClear();
          dispatch(getAllEmployeeMaster(1, 5));
        });
      } else {
        dispatch(updateEmployeeMaster(emp_data)).then((res) => {
          setis_edit(false);
          handleClear();
          dispatch(getAllEmployeeMaster(1, 5));
          if (res.response.data.success) {
            alert("Updated");
          }
        });
      }
    } else {
      console.log("------ERRORS", errors);
    }
  };

  const onChangeContact = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      //this.setState({value: e.target.value})
      set_contact_number(e.target.value);
      if (e.target.value.length > 10) {
        errors.contact_number = true;
      } else {
        errors.contact_number = false;
      }
    }
  };

  const validate = (data) => {
    const errors = {};
    console.log("-------- DATA  -------", data);

    if (!data.location) {
      errors.location = true;
    }
    if (!data.name) {
      errors.name = true;
    }
    if (!data.aadhar_number) {
      errors.aadhar_number = true;
    }

    if (!data.address) {
      errors.address = true;
    }

    if (!data.dob) {
      errors.dob = "Date Of Birth";
    }
    if (!data.contact_number || data.contact_number.length > 10) {
      errors.contact_number = true;
    }

    if (!data.qualification) {
      errors.qualification = true;
    }
    if (!data.department) {
      errors.department = true;
    }

    if (!data.salary) {
      errors.salary = true;
    }

    if (!data.master) {
      errors.master = true;
    }
    if (!data.role) {
      errors.role = true;
    }
    if (!data.responsibility) {
      errors.responsibility = true;
    }

    if (!data.joining_date) {
      errors.joining_date = "Joining Date";
    }
    // if (!data.reliving_date) {
    //   errors.reliving_date = "Reliving Date";
    // }

    return errors;
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <Paper className={classes.paper}>
            <Sidebar title="Employee Master" />
          </Paper>
        </Grid>

        <Grid item xs={10} style={{ marginTop: "5rem" }}>
          <Paper className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Location"
                  onChange={(e) => set_location(e.target.value)}
                  variant="outlined"
                  value={location}
                  fullWidth={true}
                  autoFocus={false}
                  error={errors.location}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Name"
                  onChange={(e) => {
                    set_name(e.target.value);
                  }}
                  value={name}
                  variant="outlined"
                  fullWidth={true}
                  autoFocus={true}
                  error={errors.name}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Aadhar Number"
                  onChange={(e) => {
                    set_aadhar_number(e.target.value);
                  }}
                  value={aadhar_number}
                  variant="outlined"
                  fullWidth={true}
                  autoFocus={false}
                  error={errors.aadhar_number}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Contact Number"
                  // onChange={onChangeContact}
                  onChange={(e) => onChangeContact(e)}
                  variant="outlined"
                  value={contact_number}
                  fullWidth={true}
                  autoFocus={false}
                  error={errors.contact_number}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} className="mt-5">
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Address"
                  onChange={(e) => {
                    set_address(e.target.value);
                  }}
                  value={address}
                  variant="outlined"
                  fullWidth={true}
                  autoFocus={false}
                  error={errors.address}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="date"
                  label="DOB"
                  onChange={(e) => {
                    set_dob(e.target.value);
                  }}
                  type="date"
                  value={dob}
                  //defaultValue={dob}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <br />
                {errors.dob && <OnLineErrors text={errors.dob} />}
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Qualification"
                  onChange={(e) => set_qualification(e.target.value)}
                  variant="outlined"
                  value={qualification}
                  fullWidth={true}
                  autoFocus={false}
                  error={errors.qualification}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Department"
                  onChange={(e) => set_department(e.target.value)}
                  variant="outlined"
                  value={department}
                  fullWidth={true}
                  autoFocus={false}
                  error={errors.department}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} className="mt-5">
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Salary"
                  onChange={(e) => set_salary(e.target.value)}
                  variant="outlined"
                  fullWidth={true}
                  value={salary}
                  autoFocus={false}
                  error={errors.salary}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Master"
                  onChange={(e) => set_master(e.target.value)}
                  variant="outlined"
                  fullWidth={true}
                  value={master}
                  autoFocus={false}
                  error={errors.master}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Role"
                  onChange={(e) => set_role(e.target.value)}
                  variant="outlined"
                  fullWidth={true}
                  value={role}
                  autoFocus={false}
                  error={errors.role}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Responsibility"
                  onChange={(e) => set_responsibility(e.target.value)}
                  variant="outlined"
                  fullWidth={true}
                  value={responsibility}
                  autoFocus={false}
                  error={errors.responsibility}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} className="mt-5">
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="date"
                  label="Joining Date"
                  onChange={(e) => {
                    set_joining_date(e.target.value);
                  }}
                  type="date"
                  // defaultValue={joining_date}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={joining_date}
                />
                <br />
                {errors.joining_date && (
                  <OnLineErrors text={errors.joining_date} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="date"
                  label="Reliving Date"
                  onChange={(e) => {
                    set_reliving_date(e.target.value);
                  }}
                  type="date"
                  value={reliving_date}
                  //defaultValue={reliving_date}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <br />
                {errors.reliving_date && (
                  <OnLineErrors text={errors.reliving_date} />
                )}
              </Grid>
            </Grid>

            <Grid container spacing={3} className="mt-5">
              <Grid item xs={12} sm={4}></Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<SaveIcon />}
                  onClick={Submit}
                >
                  {is_edit === false ? "Submit" : "Update"}
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}></Grid>
            </Grid>
          </Paper>

          <ViewemployeeMaster
            onHandleEdit={handleEdit}
            onHandleDelete={handleDelete}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));
