import axios from 'axios';
import { apiUrl } from "../../config";


export const addRegister = (reg)=>async dispatch=>{
console.log('ACTION------>',reg)
       dispatch({type:'ADD_REGISTER_REQUEST'})
       try {
           const response = await axios.post(`${apiUrl}/api/register/create`,reg)
           console.log('+++ add',response)
           dispatch({type:'ADD_REGISTER_SUCCESS'})
           return{
               response
           }
          
       } catch (error) {
           dispatch({type:'ADD_REGISTER_FAILED', payload:error}) 
       }
   }



export const allRegisterClear = (acc)=>async dispatch=>{
    try {
      
        dispatch({type:'ALL_REGISTER_CLEAR'})
    } catch (error) {
        dispatch({type:'ALL_REGISTER_FAILED', payload:error}) 
    }
}   


export const getAllRegisterLength=()=>async dispatch=>{
  
    try{
        const response =await axios.get(`${apiUrl}/api/register/getallregister_length`)    
        console.log('%%%111%%%',response.data.results[0].tot);
        return{  response  }  
    } catch(error){   
    }
    }



export const getAllRegister=(offset,row_count)=>async dispatch=>{
    dispatch({type:'GET_REGISTER_REQUEST'})

try{
    const response =await axios.get(`${apiUrl}/api/register/getAllRegisters/${offset}/${row_count}`)    
 
    dispatch({type:'GET_REGISTER_SUCCESS',payload:response.data.results})
} catch(error){
    dispatch({type:'GET_REGISTER_FAILED', payload:error})
}
}


export const deleteRegister = (accid)=>async dispatch=>{
    try {
        const response = await axios.post(`${apiUrl}/api/register/delete`,{accid})    
        return{  response  }
      
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}



export const searchRegister = (val)=>async dispatch=>{
    const data={id:val}
    try {
        const response = await axios.post(`${apiUrl}/api/register/search`,data)          
        return{  response  }
       // window.location.reload()
    } catch (error) {
        return{  error  }
        //alert('Something Went Wrong')
        console.log(error)
    }
}


