import React, { useState, useEffect } from "react";
import * as moment from "moment";
import { TextField, Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import { apiUrl } from "../../../config";
import jwt_decode from "jwt-decode";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import {
  getAllEmployeeMaster,
  allEmployeeMasterClear,
  getAllEmployeeMasterLength,
} from "../../../redux/actions/employeemasterAction";

import view from "../../../resources/img/view.png";
import RadioComponent from "../../../components/Radio";
import { CSVLink, CSVDownload } from "react-csv";
import OnLineErrors from "../../../components/OnLineErrors";
import Select from "react-select";
import { saveAs } from "file-saver";
import axios from "axios";

import download from "../../../resources/img/download.png";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch, useSelector } from "react-redux";

import "./viewemployeemaster.css";

var datetime = new Date();
var tdy_date = datetime
  .toISOString()
  .slice(0, 10)
  .split("-")
  .reverse()
  .join("-");
var t_date = datetime.toISOString().slice(0, 10).split("-").join("-");

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function ViewEmployeeMaster(props) {
  const classes = useStyles();

  const [id, setid] = useState("");

  const [errors, seterrors] = useState([]);
  const [search, setsearch] = useState("");
  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");
  const [trigger, settrigger] = useState("#myModal");

  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: errors.units === "*" ? "red" : "gray",
    }),
  };

  const employee_master_state = useSelector(
    (state) => state.getAllEmployeemasterReducer
  );

  const { employee_master } = employee_master_state;
  //console.log("!!!!!!!", employee_master);

  const userState = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userState;
  //console.log("!!!CURRENT USER!!!!", currentUser);

  useEffect(() => {
    // if (currentUser.length > 0) {
    //   var decoded = jwt_decode(userState.currentUser);
    //   setuser(decoded.username);
    //   if (decoded.role !== "admin") {
    //     window.location.href = "/login";
    //   }
    // } else {
    //   window.location.href = "/";
    // }

    dispatch(allEmployeeMasterClear());
    dispatch(getAllEmployeeMasterLength()).then((response) => {
      setTot(response.response.data.results[0].tot);
    });
    dispatch(getAllEmployeeMaster(1, itemsPerPage));
  }, []);

  //console.log('!!!!--USER-!!!',user)

  useEffect(() => {
    setData(employee_master);
  }, [employee_master]);

  const dispatch = useDispatch();

  const handleClear = () => {};

  // ------------------------- EDIT START --------------------------- //

  // ------------------------- Pagination start ------------------------------ //
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const [tot, setTot] = useState(0);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [data, setData] = useState([]);
  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
    dispatch(getAllEmployeeMaster(Number(event.target.id), itemsPerPage));
  };
  const pages = [];
  for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
    pages.push(i);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    dispatch(getAllEmployeeMaster(currentPage, itemsPerPage));
  }, [currentPage, itemsPerPage]);

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
  }
  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
  }
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleLoadMore = () => {
    setcurrentPage(1);
    setmaxPageNumberLimit(5);
    setminPageNumberLimit(0);
    setitemsPerPage(itemsPerPage + 5);
  };

  // ------------------------ Pagination end ------------------------ //

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#0097e6" }}>
                <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  <TableCell align="left">Location</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Aadhar Number</TableCell>
                  <TableCell align="left">Contact Number</TableCell>
                  <TableCell align="left">Address</TableCell>
                  <TableCell align="left">DateOfBirth</TableCell>
                  <TableCell align="left">Qualification</TableCell>
                  <TableCell align="left">Department</TableCell>
                  <TableCell align="left">Salary</TableCell>
                  <TableCell align="left">Master</TableCell>
                  <TableCell align="left">Role</TableCell>
                  <TableCell align="left">Responsibility</TableCell>
                  <TableCell align="left">JoiningDate</TableCell>
                  <TableCell align="left">RelivingDate</TableCell>
                  <TableCell align="left"> </TableCell>
                  <TableCell align="left"> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="left">{row.location}</TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.aadhar_number}</TableCell>
                    <TableCell align="left">{row.contact_number}</TableCell>
                    <TableCell align="left">{row.address}</TableCell>
                    <TableCell align="left">
                      {" "}
                      {moment(row.dob).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell align="left">{row.qualification}</TableCell>
                    <TableCell align="left">{row.department}</TableCell>
                    <TableCell align="left">{row.salary}</TableCell>
                    <TableCell align="left">{row.master}</TableCell>
                    <TableCell align="left">{row.role}</TableCell>
                    <TableCell align="left">{row.responsibility}</TableCell>
                    <TableCell align="left">
                      {moment(row.joining_date).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell align="left">
                      {row.reliving_date == null
                        ? ""
                        : moment(row.reliving_date).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell align="left">
                      <EditIcon
                        onClick={() => {
                          props.onHandleEdit(row);
                        }}
                        style={{ color: "#ff9f43" }}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <DeleteIcon
                        onClick={() => {
                          props.onHandleDelete(row);
                        }}
                        color="secondary"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid container spacing={3} className="mt-5">
        <Grid item xs={12}>
          <div className="pageNumbers">
            <button
              onClick={handlePrevbtn}
              disabled={currentPage == pages[0] ? true : false}
            >
              {" "}
              &lt;&lt;
            </button>

            {pageDecrementBtn}
            {renderPageNumbers}
            {pageIncrementBtn}
            <button
              onClick={handleNextbtn}
              disabled={currentPage == pages[pages.length - 1] ? true : false}
            >
              {" "}
              &gt;&gt;
            </button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 50px",
            }}
          >
            <button onClick={handleLoadMore} className="loadmore">
              Load More
            </button>
          </div>
        </Grid>
      </Grid>

      <></>
    </>
  );
}
