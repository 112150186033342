import React from "react";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "popper.js/dist/umd/popper.min.js";

import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import CompanyMaster from "./pages/companymaster/CompanyMaster";
import ViewCompanyMaster from "./pages/companymaster/ViewCompanyMaster";
import PurchaseVendor from "./pages/purchase/purchasevendor/PurchaseVendor";
import SalesVendor from "./pages/salesvendor/SalesVendor";
import PurchaseOrder from "./pages/purchase/purchaseorder/PurchaseOrder";
import EmployeeMaster from "./pages/hr/employeemaster/EmployeeMaster";
import ProductionPlan from "./pages/productionplan/ProductionPlan";
import Costing from "./pages/costing/Costing";
import DispatchMaster from "./pages/dispatchmaster/DispatchMaster";
import Feedback from "./pages/feedback/Feedback";
import TaskAssignment from "./pages/taskassignment/TaskAssignment";

import Registration from "./pages/registration/Registration";
import ViewRegistration from "./pages/registration/ViewRegistration";
import ViewReviewerEditor from "./pages/reviewer_editor/ViewReviewerEditor";
import ReviewerEditor from "./pages/reviewer_editor/ReviewerEditor";

import { Redirect } from "react-router-dom";
import { BrowserRouter, Route } from "react-router-dom";

export default function Router() {
  return (
    <BrowserRouter>
      <Route exact path="/" component={Login} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/companymaster" component={CompanyMaster} />
      <Route exact path="/viewcompanymaster" component={ViewCompanyMaster} />
      <Route exact path="/purchasevendor" component={PurchaseVendor} />
      <Route exact path="/salesvendor" component={SalesVendor} />
      <Route exact path="/purchaseorder" component={PurchaseOrder} />
      <Route exact path="/employeemaster" component={EmployeeMaster} />
      <Route exact path="/productionplan" component={ProductionPlan} />
      <Route exact path="/costing" component={Costing} />
      <Route exact path="/dispatchmaster" component={DispatchMaster} />
      <Route exact path="/feedback" component={Feedback} />
      <Route exact path="/taskassignment" component={TaskAssignment} />
      {/* <Route render={() => <Redirect to={{ pathname: "/" }} />} /> */}

      <Route exact path="/registration" component={Registration} />
      <Route exact path="/viewregister" component={ViewRegistration} />
      <Route exact path="/reviewer-editor" component={ReviewerEditor} />
      <Route exact path="/view-reviewer" component={ViewReviewerEditor} />
    </BrowserRouter>
  );
}
