import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { TextField, Button, Checkbox } from "@material-ui/core";
import { loginUser } from "../../redux/actions/userActions";
import { addRegister } from "../../redux/actions/registerAction";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import Navbar from "../../components/AdminNavbar/AdminNavbar";
//import "./Registration.css";

export default function Login() {
  const [first_name, set_first_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [designation, set_designation] = useState("");
  const [affiliation, set_affiliation] = useState("");
  const [address, set_address] = useState("");
  const [city, set_city] = useState("");
  const [state, set_state] = useState("");
  const [zip_postal_code, set_zip_postal_code] = useState("");
  const [country, set_country] = useState("");
  const [email, set_email] = useState("");
  const [contact_number, set_contact_number] = useState("");

  const [paper_title, set_paper_title] = useState("");
  const [abstract, set_abstract] = useState("");
  const [keywords, set_keywords] = useState("");
  const [
    corresponding_author_details,
    set_corresponding_author_details,
  ] = useState("");
  const [co_author_details, set_co_author_details] = useState("");
  const [checked, setchecked] = useState(false);

  const [errors, seterrors] = useState([]);

  const loginstate = useSelector((state) => state.loginUserReducer);
  const { error, loading } = loginstate;

  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const handleClear = () => {
    set_first_name("");
    set_last_name("");
    set_designation("");
    set_affiliation("");
    set_address("");
    set_city("");
    set_state("");
    set_zip_postal_code("");
    set_country("");
    set_email("");
    set_contact_number("");
    set_paper_title("");
    set_abstract("");
    set_keywords("");
    set_corresponding_author_details("");
    set_co_author_details("");
  };

  const handleCheck = (e) => {
    this.setState({ checked: e.target.checked }, () => {
      if (this.state.checked === true) {
        this.setState({ page: "consentpage" });
      } else {
        this.setState({ page: "doctorspage" });
      }
    });
  };

  const Submit = () => {
    const reg_data = {
      first_name,
      last_name,
      designation,
      affiliation,
      address,
      city,
      state,
      zip_postal_code,
      country,
      email,
      contact_number,
      paper_title,
      abstract,
      keywords,
      corresponding_author_details,
      co_author_details,
    };
    console.log("##### REG DATA  ######", reg_data);
    const errors = validate(reg_data);
    console.log("##### ERRORS  ######", errors);
    seterrors(errors);

    if (Object.keys(errors).length === 0) {
      dispatch(addRegister(reg_data)).then((response) => {
        console.log("@#@#@#@#", response.response.data.msg);
        alert(response.response.data.msg);
        handleClear();
      });
    } else {
      console.log("@#@#@#@#", errors);
    }
  };

  const validate = (data) => {
    const errors = {};
    console.log("-------- DATA  -------", data);
    if (!data.first_name) {
      errors.first_name = "*";
    }
    if (!data.last_name) {
      errors.last_name = "*";
    }
    if (!data.designation) {
      errors.designation = "*";
    }
    if (!data.affiliation) {
      errors.affiliation = "*";
    }
    if (!data.address) {
      errors.address = "*";
    }
    if (!data.city) {
      errors.city = "*";
    }
    if (!data.state) {
      errors.state = "*";
    }
    if (!data.zip_postal_code) {
      errors.zip_postal_code = "*";
    }
    if (!data.country) {
      errors.country = "*";
    }
    if (!data.email) {
      errors.email = "*";
    }
    if (!data.contact_number) {
      errors.contact_number = "*";
    }
    if (!data.paper_title) {
      errors.paper_title = "*";
    }
    if (!data.abstract) {
      errors.abstract = "*";
    }
    if (!data.keywords) {
      errors.keywords = "*";
    }
    if (!data.corresponding_author_details) {
      errors.corresponding_author_details = "*";
    }
    if (!data.co_author_details) {
      errors.co_author_details = "*";
    }

    return errors;
  };

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Navbar />
      <Section>
        <>
          <h2
            className="text-center"
            style={{ color: "#1e3799", margin: "1rem" }}
          >
            ICAI-PAFR Registration Form
          </h2>
          <ul className="wrapper">
            <h5 style={{ color: "#21c87a" }}>Personal Details</h5>
            <li className="form-row">
              <label htmlFor="name">First Name</label>
              <TextField
                className="txtfld"
                id="outlined-basic"
                name="first_name"
                error={errors.first_name ? true : false}
                variant="outlined"
                fullWidth={false}
                onChange={(e) => {
                  set_first_name(e.target.value);
                }}
                value={first_name}
              />
            </li>
            <li className="form-row">
              <label htmlFor="name">Last Name</label>
              <TextField
                className="txtfld"
                id="outlined-basic"
                name="last_name"
                error={errors.last_name ? true : false}
                variant="outlined"
                fullWidth={false}
                onChange={(e) => {
                  set_last_name(e.target.value);
                }}
                value={last_name}
              />
            </li>
            <li className="form-row">
              <label htmlFor="name">Designation</label>
              <TextField
                className="txtfld"
                id="outlined-basic"
                name="designation"
                error={errors.designation ? true : false}
                variant="outlined"
                fullWidth={false}
                onChange={(e) => {
                  set_designation(e.target.value);
                }}
                value={designation}
              />
            </li>
            <li className="form-row">
              <label htmlFor="name">Affiliation</label>
              <TextField
                className="txtfld"
                id="outlined-basic"
                name="affiliation"
                error={errors.affiliation ? true : false}
                variant="outlined"
                fullWidth={false}
                onChange={(e) => {
                  set_affiliation(e.target.value);
                }}
                value={affiliation}
              />
            </li>
            <li className="form-row">
              <label htmlFor="name">Address</label>
              <TextField
                className="txtfld"
                id="outlined-basic"
                name="address"
                error={errors.address ? true : false}
                variant="outlined"
                fullWidth={false}
                onChange={(e) => {
                  set_address(e.target.value);
                }}
                value={address}
              />
            </li>
            <li className="form-row">
              <label htmlFor="name">City</label>
              <TextField
                className="txtfld"
                id="outlined-basic"
                name="city"
                error={errors.city ? true : false}
                variant="outlined"
                fullWidth={false}
                onChange={(e) => {
                  set_city(e.target.value);
                }}
                value={city}
              />
            </li>
            <li className="form-row">
              <label htmlFor="name">State</label>
              <TextField
                className="txtfld"
                id="outlined-basic"
                name="state"
                error={errors.state ? true : false}
                variant="outlined"
                fullWidth={false}
                onChange={(e) => {
                  set_state(e.target.value);
                }}
                value={state}
              />
            </li>
            <li className="form-row">
              <label htmlFor="name">Zip / Postal Code</label>
              <TextField
                className="txtfld"
                id="outlined-basic"
                name="zip_postal_code"
                error={errors.zip_postal_code ? true : false}
                variant="outlined"
                fullWidth={false}
                onChange={(e) => {
                  set_zip_postal_code(e.target.value);
                }}
                value={zip_postal_code}
              />
            </li>
            <li className="form-row">
              <label htmlFor="name">Country</label>
              <TextField
                className="txtfld"
                id="outlined-basic"
                name="country"
                error={errors.country ? true : false}
                variant="outlined"
                fullWidth={false}
                onChange={(e) => {
                  set_country(e.target.value);
                }}
                value={country}
              />
            </li>
            <li className="form-row">
              <label htmlFor="name">E-Mail</label>
              <TextField
                className="txtfld"
                id="outlined-basic"
                name="email"
                error={errors.email ? true : false}
                variant="outlined"
                fullWidth={false}
                onChange={(e) => {
                  set_email(e.target.value);
                }}
                value={email}
              />
            </li>
            <li className="form-row">
              <label htmlFor="name">Contact Number</label>
              <TextField
                className="txtfld"
                id="outlined-basic"
                name="contact_number"
                error={errors.country ? true : false}
                variant="outlined"
                fullWidth={false}
                onChange={(e) => {
                  set_contact_number(e.target.value);
                }}
                value={contact_number}
              />
            </li>
            <h5 style={{ color: "#21c87a" }}>Paper Details</h5>
            <li className="form-row">
              <label htmlFor="name">Paper Title </label>
              <TextField
                className="txtfld"
                id="outlined-basic"
                name="paper_title"
                error={errors.paper_title ? true : false}
                variant="outlined"
                fullWidth={false}
                onChange={(e) => {
                  set_paper_title(e.target.value);
                }}
                value={paper_title}
              />
            </li>
            <li className="form-row">
              <label htmlFor="name">Abstract</label>
              <TextField
                className="txtfld"
                id="outlined-basic"
                name="abstract"
                error={errors.abstract ? true : false}
                variant="outlined"
                fullWidth={false}
                onChange={(e) => {
                  set_abstract(e.target.value);
                }}
                value={abstract}
              />
            </li>
            <li className="form-row">
              <label htmlFor="name">Keywords</label>
              <TextField
                className="txtfld"
                id="outlined-basic"
                name="keywords"
                error={errors.keywords ? true : false}
                variant="outlined"
                fullWidth={false}
                onChange={(e) => {
                  set_keywords(e.target.value);
                }}
                value={keywords}
              />
            </li>
            <li className="form-row">
              <label htmlFor="name">Corresponding Author Details</label>
              <TextField
                className="txtfld"
                id="outlined-basic"
                name="corresponding_author_details"
                error={errors.corresponding_author_details ? true : false}
                variant="outlined"
                fullWidth={false}
                onChange={(e) => {
                  set_corresponding_author_details(e.target.value);
                }}
                value={corresponding_author_details}
              />
            </li>
            <li className="form-row">
              <label htmlFor="name">Co Author Details </label>
              <TextField
                className="txtfld"
                id="outlined-basic"
                name="paper_title"
                error={errors.co_author_details ? true : false}
                variant="outlined"
                fullWidth={false}
                onChange={(e) => {
                  set_co_author_details(e.target.value);
                }}
                value={co_author_details}
              />
            </li>
            <ul className="form-row">
              <label htmlFor="name">Declaration: </label>

              <li style={{ display: "inline-block" }}>
                <Checkbox
                  checked={checked}
                  style={{ color: "green" }}
                  onChange={(e) => {
                    setchecked(e.target.checked);
                  }}
                />{" "}
                By submitting this registration form, I confirm that the
                information provided is accurate and complete.
              </li>
            </ul>
            <li className="form-row">
              <Button
                variant="contained"
                color="primary"
                onClick={Submit}
                disabled={checked === false ? "disabled" : ""}
              >
                {" "}
                Submit{" "}
              </Button>
            </li>
          </ul>
        </>
      </Section>
    </div>
  );
}

const Section = styled.section`
  margin: 10px auto;
  max-width: 700px !important;
  background-color: "red !important";

  .wrapper {
    background-color: #ffffff;
    border: 2px solid #3b2774;
    list-style-type: none;
    padding: 30px 20px;
    border-radius: 10px;
  }
  .form-row {
    display: flex;
    justify-content: flex-end;
    padding: 0.3rem;
  }
  .form-row > label {
    padding: 0.5em 8em 0.5em 0;
    flex: 1;
    color: #3b2774;
  }

  .form-row .txtfld {
    flex: 2;
  }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    .form-row {
      display: flex;
      flex-direction: column;
    }
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    .form-row {
      display: flex;
      flex-direction: column;
    }
  }
`;
