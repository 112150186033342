export const getAddCompanymasterReducer = (state = {}, action) => {
  switch (action.type) {
    case "ADD_COMPANYMASTER_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "ADD_COMPANYMASTER_SUCCESS":
      return {
        loading: false,
        success: true,
      };
    case "ADD_COMPANYMASTER_FAILED":
      return {
        loading: false,
        error: action.payload,
      };

    case "ALL_COMPANYMASTER_CLEAR":
      return {
        loading: false,
        success: false,
      };

    default:
      return state;
  }
};

export const getAllCompanymasterReducer = (
  state = { company_master: [] },
  action
) => {
  switch (action.type) {
    case "GET_COMPANYMASTER_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "GET_COMPANYMASTER_SUCCESS":
      return {
        loading: false,
        company_master: action.payload,
      };
    case "GET_COMPANYMASTER_FAILED":
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
