import { combineReducers } from "redux";
//import { createStore, applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
//import thunk from "redux-thunk";
//import {composeWithDevTools} from 'redux-devtools-extension'

import { loginUserReducer } from "./reducers/userReducers";
import {
  getAddCompanymasterReducer,
  getAllCompanymasterReducer,
} from "./reducers/companymasterReducers";
import {
  getAddEmployeemasterReducer,
  getAllEmployeemasterReducer,
} from "./reducers/employeemasterReducers";
import {
  getAddRegisterReducer,
  getAllRegisterReducer,
} from "./reducers/registerReducers";
import {
  getAddReviewerEditorReducer,
  getAllReviewerEditorReducer,
} from "./reducers/reviewerEditorReducers";
import { persistReducer, PERSIST } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: "my-research-super-secret-key",
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
};

const finalReducer = combineReducers({
  loginUserReducer: loginUserReducer,
  getAddRegisterReducer: getAddRegisterReducer,
  getAllRegisterReducer: getAllRegisterReducer,
  getAddReviewerEditorReducer: getAddReviewerEditorReducer,
  getAllReviewerEditorReducer: getAllReviewerEditorReducer,

  getAddCompanymasterReducer: getAddCompanymasterReducer,
  getAllCompanymasterReducer: getAllCompanymasterReducer,
  getAddEmployeemasterReducer: getAddEmployeemasterReducer,
  getAllEmployeemasterReducer: getAllEmployeemasterReducer,
});

const persistedReducer = persistReducer(persistConfig, finalReducer);

//const composeEnhancers = composeWithDevTools({})
//const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)))

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST],
      },
    }),
});

export default store;
