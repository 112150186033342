import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import styled from "styled-components";
import { TextField,Button } from '@material-ui/core';
import {loginUser} from '../../redux/actions/userActions';
import {addReviewerEditor} from '../../redux/actions/reviewerEditorAction';
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import Navbar  from '../../components/AdminNavbar/AdminNavbar';
import './ReviewerEditor.css';
import Sidebar from "../../components/Sidebar";

export default function ReviewerEditor() {

  const [reviewer_editor_name, set_reviewer_editor_name] = useState("");
  const [password, set_password] = useState("");
  const [confirm_password, set_confirm_password] = useState("");
  const [email, set_email] = useState("");
  const [contact_number, set_contact_number] = useState("");
  const [postal_address, set_postal_address] = useState("");
  const [country, set_country] = useState("");
  const [qualification, set_qualification] = useState("");
  const [affiliation, set_affiliation] = useState("");
  const [university_company, set_university_company] = useState("");
  const [research_interest, set_research_interest] = useState("");
  const [lqc_cv, setlqc_cv] = useState("");
  const [scopus_id, set_scopus_id] = useState("");
  const [orcid_id, set_orcid_id] = useState("");
  const [google_scholar_id, set_google_scholar_id] = useState("");
  const [wos_researcher_id, set_wos_researcher_id] = useState("");

  const [errors, seterrors] = useState([]);


  const loginstate = useSelector(state=>state.loginUserReducer);
  const {error, loading} = loginstate

  const dispatch = useDispatch()

  useEffect(()=>{
     
  },[])

  const handleClear = () => {
    set_reviewer_editor_name('');
    set_password('');
    set_email('');
    set_contact_number('');
    set_country('');
    set_qualification('');
    set_affiliation('');
    set_university_company('');
    set_research_interest('');
    setlqc_cv('');
    set_scopus_id('');
    set_orcid_id('');
    set_google_scholar_id('');
    set_wos_researcher_id('');
  }


       const Submit= () =>{      
        const rev_data = { reviewer_editor_name, password,confirm_password, email, contact_number, postal_address, country, qualification, affiliation, university_company, research_interest, lqc_cv, scopus_id, orcid_id, google_scholar_id, wos_researcher_id }
        console.log('##### REV DATA  ######', rev_data)
         const errors = validate(rev_data);
         console.log('##### ERRORS  ######', errors)
         seterrors(errors)
       
        if (Object.keys(errors).length === 0) {  
          dispatch(addReviewerEditor(rev_data)).then(response=>{
            console.log('@#@#@#@#',response.response.data.msg)
            alert(response.response.data.msg)
            handleClear();
          })  
        }
        else{
         console.log('@#@#@#@#',errors)
        }
     }   



     const validate = (data) => {
      const errors={};  
      console.log('-------- DATA  -------', data)

      
        if (!data.reviewer_editor_name) {
          errors.reviewer_editor_name = "*"
        }              
        if (!data.password) {
          errors.password = "*"
        } 
        if (data.password !== data.confirm_password) {
          errors.confirm_password = "*"
        }  
        if (!data.email) {
          errors.email = "*"
        }  
        if (!data.contact_number) {
          errors.contact_number = "*"
        } 
        if (!data.postal_address) {
          errors.postal_address = "*"
        } 
        if (!data.country) {
          errors.country = "*"
        } 
        if (!data.qualification) {
          errors.qualification = "*"
        } 
        if (!data.affiliation) {
          errors.affiliation = "*"
        }          
           
        if (!data.university_company) {
          errors.university_company = "*"
        } 
        if (!data.research_interest) {
          errors.research_interest = "*"
        } 
        if (!data.lqc_cv) {
          errors.lqc_cv = "*"
        } 
        if (!data.scopus_id) {
          errors.scopus_id = "*"
        } 
        if (!data.orcid_id) {
          errors.orcid_id = "*"
        } 
        if (!data.google_scholar_id) {
          errors.google_scholar_id = "*"
        } 
        if (!data.wos_researcher_id) {
          errors.wos_researcher_id = "*"
        } 
           
      return errors;
    };




  return (
    <div style={{backgroundColor:'#fff'}}>
  <Sidebar />
<Section>
<>
<h2 className="text-center" style={{color:'rgb(30, 55, 153)',margin:'1rem'}}> Reviewer & Editor Registration Form</h2>
  <ul className="wrapper">
   
    <li className="form-row">
      <label htmlFor="name">Reviewer/Editor Name</label>      
     <TextField className='txtfld' id="outlined-basic"  name='reviewer_editor_name' error={ errors.reviewer_editor_name ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {set_reviewer_editor_name(e.target.value)}}
        value={reviewer_editor_name}
       />   
    </li>
    <li className="form-row">
      <label htmlFor="name">Password</label>      
     <TextField className='txtfld' id="outlined-basic" type="password"  name='password' error={ errors.password ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {set_password(e.target.value)}}
        value={password}
       />   
    </li>
    <li className="form-row">
      <label htmlFor="name">Confirm Password </label>      
     <TextField className='txtfld' id="outlined-basic" type="password" name='confirm_password' error={ errors.confirm_password ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => { set_confirm_password(e.target.value)}}
        value={confirm_password}
       />   
    </li>
    <li className="form-row">
      <label htmlFor="name">E-Mail</label>      
     <TextField className='txtfld' id="outlined-basic"  name='email' error={ errors.email ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {set_email(e.target.value)}}
        value={email}
       />   
    </li>
    <li className="form-row">
      <label htmlFor="name">Contact Number</label>      
     <TextField className='txtfld' id="outlined-basic"  name='contact_number' error={ errors.country ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {set_contact_number(e.target.value)}}
        value={contact_number}
       />   
    </li>
    
    <li className="form-row">
      <label htmlFor="name">PostalAddress</label>      
     <TextField className='txtfld' id="outlined-basic"  name='postal_address' error={ errors.postal_address ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {set_postal_address(e.target.value)}}
        value={postal_address}
       />   
    </li>
    <li className="form-row">
      <label htmlFor="name">Country</label>      
     <TextField className='txtfld' id="outlined-basic"  name='country' error={ errors.country ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {set_country(e.target.value)}}
        value={country}
       />   
    </li>
    <li className="form-row">
      <label htmlFor="name">Qualification</label>      
     <TextField className='txtfld' id="outlined-basic"  name='qualification' error={ errors.qualification ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {set_qualification(e.target.value)}}
        value={qualification}
       />   
    </li>
    
    <li className="form-row">
      <label htmlFor="name">Affiliation</label>      
     <TextField className='txtfld' id="outlined-basic"  name='affiliation' error={ errors.affiliation ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {set_affiliation(e.target.value)}}
        value={affiliation}
       />   
    </li>
    <li className="form-row">
      <label htmlFor="name">University/Company</label>      
     <TextField className='txtfld' id="outlined-basic"  name='university_company' error={ errors.university_company ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {set_university_company(e.target.value)}}
        value={university_company}
       />   
    </li>
    <li className="form-row">
      <label htmlFor="name">Research Interest</label>      
     <TextField className='txtfld' id="outlined-basic"  name='research_interest' error={ errors.research_interest ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {set_research_interest(e.target.value)}}
        value={research_interest}
       />   
    </li>
    <li className="form-row">
      <label htmlFor="name">Latest Qualification Certificate or CV</label>     
      <div style={{marginRight:'0rem'}}> 
          <input type="file" name="lqc_cv"    
              style={{color:'transparent'}}      
              onChange={(e) => {  setlqc_cv(e.target.files[0])        
              }}
            />           
              <p style={{color:'blue'}}>{lqc_cv.name}</p>   
           </div>
           
    </li>
    <li className="form-row">
      <label htmlFor="name">Scopus ID	</label>      
     <TextField className='txtfld' id="outlined-basic"  name='scopus_id' error={ errors.scopus_id ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {set_scopus_id(e.target.value)}}
        value={scopus_id}
       />   
    </li>
    <li className="form-row">
      <label htmlFor="name">Orcid ID	</label>      
     <TextField className='txtfld' id="outlined-basic"  name='orcid_id' error={ errors.orcid_id ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {set_orcid_id(e.target.value)}}
        value={orcid_id}
       />   
    </li>
    <li className="form-row">
      <label htmlFor="name">Google Scholar ID</label>      
     <TextField className='txtfld' id="outlined-basic"  name='google_scholar_id' error={ errors.google_scholar_id ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {set_google_scholar_id(e.target.value)}}
        value={google_scholar_id}
       />   
    </li>
    <li className="form-row">
      <label htmlFor="name">WoS Researcher ID</label>      
     <TextField className='txtfld' id="outlined-basic"  name='wos_researcher_id' error={ errors.wos_researcher_id ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {set_wos_researcher_id(e.target.value)}}
        value={wos_researcher_id}
       />   
    </li>
    <li className="form-row">
        <Button variant="contained" color="primary" onClick={Submit}>  Submit </Button>
    </li>
  </ul>

</>
    </Section>

    </div>
      );
}


const Section = styled.section`

margin:10px auto;
max-width:700px !important;
background-color:'red !important';

 .wrapper {
    background-color: #ffffff;
    border:2px solid #3B2774;
    list-style-type: none;
    padding: 30px 20px;
    border-radius: 10px;
  }
  .form-row {
    display: flex;
    justify-content: flex-end;
    padding: 0.3rem;
  }
  .form-row > label {
    padding: .5em 8em .5em 0;
    flex: 1;
    color:#3B2774
  }  

 
  .form-row .txtfld {
    flex: 2;
  }


  @media screen and (min-width: 280px) and (max-width: 720px) {
    .form-row{
      display: flex;
      flex-direction: column;
      }
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    .form-row{
      display: flex;
      flex-direction: column;
      }
  }

`;