import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, LinearProgress } from "@material-ui/core";
import { loginUser } from "../../redux/actions/userActions";
import logo from "../../assets/img/logo.png";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import "./Login.css";

export default function Login() {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");

  const loginstate = useSelector((state) => state.loginUserReducer);
  const { error, loading } = loginstate;

  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const login = () => {
    const user = {
      username,
      password,
    };
    dispatch(loginUser(user));
  };

  return (
    <div className="cover" style={{ height: "100%" }}>
      <div style={{ marginTop: "5rem" }}>
        <h1
          className="text-center"
          style={{ color: "#00a1ff", marginTop: "10rem" }}
        >
          <div className="gradient-text">
            <img src={logo} height={"100rem"} />
            <br />
            Billventory Pro
          </div>
        </h1>
        <br />
      </div>

      <div className="row justify-content-center">
        <div
          className="col-md-4 col-sm-10 mt-10 text-left shadow-lg p-5 mb-10 bg-whte rounded"
          style={{ borderTop: "4px solid  rgb(37, 59, 128)" }}
        >
          <div>
            {loading && <Loading />}
            {error && <Error error="Invalid Credentials" />}

            <h2
              className="text-center m-2"
              style={{ fontSize: "35px", color: "#0080bf" }}
            >
              Login
            </h2>
            <br />
            <div>
              <div>
                <input
                  type="text"
                  required
                  placeholder="Username"
                  className="form-control"
                  value={username}
                  onChange={(e) => {
                    setusername(e.target.value);
                  }}
                />
              </div>
              <br />
              <div style={{ marginTop: "1rem" }}>
                <input
                  type="password"
                  required
                  placeholder="Password"
                  className="form-control"
                  value={password}
                  onChange={(e) => {
                    setpassword(e.target.value);
                  }}
                />
              </div>
              <br />

              <Button
                variant="contained"
                style={{
                  borderRadius: 5,
                  backgroundColor: "rgb(23, 155, 215)",
                  color: "#FFFFFF",
                  padding: "10px 20px",
                  fontSize: "14px",
                }}
                onClick={login}
              >
                {" "}
                LOGIN{" "}
              </Button>
              <br></br>
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
}
