export const getAddEmployeemasterReducer = (state = {}, action) => {
  switch (action.type) {
    case "ADD_EMPLOYEEMASTER_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "ADD_EMPLOYEEMASTER_SUCCESS":
      return {
        loading: false,
        success: true,
      };
    case "ADD_EMPLOYEEMASTER_FAILED":
      return {
        loading: false,
        error: action.payload,
      };

    case "ALL_EMPLOYEEMASTER_CLEAR":
      return {
        loading: false,
        success: false,
      };

    default:
      return state;
  }
};

export const getAllEmployeemasterReducer = (
  state = { employee_master: [] },
  action
) => {
  switch (action.type) {
    case "GET_EMPLOYEEMASTER_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "GET_EMPLOYEEMASTER_SUCCESS":
      return {
        loading: false,
        employee_master: action.payload,
      };
    case "GET_EMPLOYEEMASTER_FAILED":
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
