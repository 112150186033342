import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextField, Button, Checkbox } from "@material-ui/core";
import { loginUser } from "../../../redux/actions/userActions";
import { addCompanymaster } from "../../../redux/actions/companymasterAction";
import Loading from "../../../components/Loading";
import Error from "../../../components/Error";

import Sidebar from "../../../components/Sidebar";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import SaveIcon from "@material-ui/icons/Save";

// import ViewCompanyMaster from "./ViewCompanyMaster";
//import './Registration.css';

export default function PurchaseOrder() {
  const classes = useStyles();

  const [name, set_name] = useState("");
  const [address, set_address] = useState("");
  const [contact_number, set_contact_number] = useState("");
  const [email, set_email] = useState("");
  const [gst_num, set_gst_num] = useState("");
  const [bank_name, set_bank_name] = useState("");
  const [account_number, set_account_number] = useState("");
  const [branch, set_branch] = useState("");
  const [ifsc, set_ifsc] = useState("");
  const [bank_address, set_bank_address] = useState("");

  const [errors, seterrors] = useState([]);
  const [errors_length, seterrors_length] = useState([]);

  const loginstate = useSelector((state) => state.loginUserReducer);
  const { error, loading } = loginstate;

  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const handleClear = () => {
    set_name("");
    set_address("");
    set_contact_number("");
    set_email("");
    set_gst_num("");
    set_bank_name("");
    set_account_number("");
    set_branch("");
    set_ifsc("");
    set_bank_address("");
  };

  const Submit = () => {
    const reg_data = {
      name,
      address,
      contact_number,
      email,
      gst_num,
      bank_name,
      account_number,
      branch,
      ifsc,
      bank_address,
    };
    console.log("##### REG DATA  ######", reg_data);
    const errors = validate(reg_data);
    console.log("##### ERRORS  ######", errors);
    seterrors(errors);
    console.log("------LENGTH CHECK----------", Object.keys(errors).length);
    if (Object.keys(errors).length === 0) {
      console.log("------check----------");
      dispatch(addCompanymaster(reg_data)).then((response) => {
        console.log("@#@#@#@#", response.response.data.msg);
        alert(response.response.data.msg);
        //handleClear();
      });
    } else {
      console.log("------ERRORS", errors);
    }
  };

  const onChangeContact = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      //this.setState({value: e.target.value})
      set_contact_number(e.target.value);
      if (e.target.value.length > 10) {
        errors.contact_number = true;
      } else {
        errors.contact_number = false;
      }
    }
  };
  const onChangeEmail = (e) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    set_email(e.target.value);
    if (!emailRegex.test(e.target.value)) {
      errors.email = true;
    } else {
      errors.email = false;
    }
  };
  const validate = (data) => {
    const errors = {};

    console.log("-------- DATA  -------", data);
    if (!data.name) {
      errors.name = true;
    }

    if (!data.address) {
      errors.address = true;
    }

    if (!data.contact_number || data.contact_number.length > 10) {
      errors.contact_number = true;
    }

    if (!data.email) {
      errors.email = true;
    } else {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(data.email)) {
        errors.email = true;
      }
    }

    if (!data.gst_num) {
      errors.gst_num = true;
    }

    if (!data.bank_name) {
      errors.bank_name = true;
    }
    if (!data.account_number) {
      errors.account_number = true;
    }

    if (!data.branch) {
      errors.branch = true;
    }

    if (!data.ifsc) {
      errors.ifsc = true;
    }
    if (!data.bank_address) {
      errors.bank_address = true;
    }

    return errors;
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <Paper className={classes.paper}>
            <Sidebar title="Purchase Order" />
          </Paper>
        </Grid>

        <Grid item xs={10} style={{ marginTop: "5rem" }}>
          {/* <Paper className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Name"
                  onChange={(e) => {
                    set_name(e.target.value);
                  }}
                  value={name}
                  variant="outlined"
                  fullWidth={true}
                  autoFocus={true}
                  error={errors.name}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Address"
                  onChange={(e) => {
                    set_address(e.target.value);
                  }}
                  value={address}
                  variant="outlined"
                  fullWidth={true}
                  autoFocus={false}
                  error={errors.address}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Contact Number"
                  // onChange={onChangeContact}
                  onChange={(e) => onChangeContact(e)}
                  variant="outlined"
                  value={contact_number}
                  fullWidth={true}
                  autoFocus={false}
                  error={errors.contact_number}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Email"
                  onChange={(e) => onChangeEmail(e)}
                  variant="outlined"
                  value={email}
                  fullWidth={true}
                  autoFocus={false}
                  error={errors.email}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} className="mt-5">
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="GST Number"
                  onChange={(e) => set_gst_num(e.target.value)}
                  variant="outlined"
                  value={gst_num}
                  fullWidth={true}
                  autoFocus={false}
                  error={errors.gst_num}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Bank Name"
                  onChange={(e) => set_bank_name(e.target.value)}
                  variant="outlined"
                  value={bank_name}
                  fullWidth={true}
                  autoFocus={false}
                  error={errors.bank_name}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Account Number"
                  onChange={(e) => set_account_number(e.target.value)}
                  variant="outlined"
                  value={account_number}
                  fullWidth={true}
                  autoFocus={false}
                  error={errors.account_number}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Branch"
                  onChange={(e) => set_branch(e.target.value)}
                  variant="outlined"
                  value={branch}
                  fullWidth={true}
                  autoFocus={false}
                  error={errors.branch}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} className="mt-5">
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="IFSC"
                  onChange={(e) => set_ifsc(e.target.value)}
                  variant="outlined"
                  fullWidth={true}
                  value={ifsc}
                  autoFocus={false}
                  error={errors.ifsc}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Bank Address"
                  onChange={(e) => set_bank_address(e.target.value)}
                  variant="outlined"
                  fullWidth={true}
                  value={bank_address}
                  autoFocus={false}
                  error={errors.bank_address}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}></Grid>

              <Grid item xs={12} sm={6} md={3}></Grid>
            </Grid>

            <Grid container spacing={3} className="mt-5">
              <Grid item xs={12} sm={4}></Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<SaveIcon />}
                  onClick={Submit}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}></Grid>
            </Grid>
          </Paper> */}

          {/* <ViewCompanyMaster /> */}
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
